import React from "react"
import useTranslations from "../utils/useTranslations"
import { CompatibilityContext } from "./compatibilityContext";
import { CSSTransition } from 'react-transition-group';
import { IoMdWarning } from 'react-icons/io';
import { IconContext } from "react-icons";
import { IoMdClose } from 'react-icons/io';
import { navigate } from '@reach/router';
import { getAndroidLink } from "../utils/getAndroidLink";

const systems = ['Win', 'Osx', 'Android']

class CompatabilityPopup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMac: false,
      isCatalina:false,
      isMobile:false,
      isLinux:false,
      isAndroid: false,  
      isOpen: false,  
      product: "Web",
      action: "downloading",
      compatibleSystems:["Win", "Osx"],
      currentSystem:"Win",
      productOs:["Win"]
    };
    this.toggle = this.toggle.bind(this);
    this.compatibilityHandler = this.compatibilityHandler.bind(this);
    this.close = this.close.bind(this);

  }

  componentDidMount(){

    this.setState({
      isMac:window.gatsbyIsMac,
      isCatalina:window.gatsbyIsCatalina,
      isMobile:window.gatsbyIsMobile,
      isLinux:window.gatsbyIsLinux,
      isAndroid:window.gatsbyIsAndroid,
      isOpen: false,
      product: "Web",
      action: "downloading",
      compatibleSystems:["Win", "Osx"],
      currentSystem:"Win",
      productOs:["Win"]
    })
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  close(){
    this.setState({
      isOpen:false
    })
  }

  openLink = (isLocalized, forceOs) => {
    this.close()
    if(isLocalized){
      let link = this.state.link
      let forceValue = (Array.isArray(forceOs)) ? forceOs[0] : forceOs
      if (forceOs === "catalinaWeb"){
        link = "/web/downloads/complete/track/stable/os/mac/"
      }
      else if(link.includes("/downloads/complete/track/stable/os/win/") && forceValue === "Osx") {
        link = link.replace('/win/', '/mac/')
      }
      else if(link.includes("/downloads/complete/track/stable/os/mac/") && forceValue === "Win") {
        link = link.replace('/mac/', '/win/')
      }
      let locale = (this.props.pageContext.locale === "en") ? "" : `/intl/${this.props.pageContext.locale}`
      navigate(`${locale}${link}`)
    }
    else if(forceOs === "Android"){
      window.open(getAndroidLink('free'), '_blank');
    }
    else if(forceOs === "iOs"){
      window.open("https://apps.apple.com/us/app/dlive-live-stream-community/id1446969385", '_blank');
    }
    else{
      window.open(this.state.link, '_blank');
    }
  }

  compatibilityHandler(e, action, product, productOs, link){

    var isCompatible = this.compatibilityCheck(product, productOs)
    if (isCompatible){
      return
    }
    e.preventDefault()

    this.setState({
      product:product,      //product options -> Web, Classic, Android, Live, uTorrent Classic
      action:action,        //action options -> downloading, buying
      productOs:productOs,   //os option -> Win, Osx, Android
      link:link
    })
    this.toggle();

  }

  compatibilityCheck(product, productOs){

    let currentSystem;
    if(!this.state.isMac && !this.state.isAndroid && !this.state.isMobile){
      currentSystem = "Win"
    }
    else if(this.state.isMac && !this.state.isAndroid && !this.state.isMobile){
      currentSystem = "Osx"
    }
    else if(!this.state.isMac && this.state.isAndroid){
      currentSystem = "Android"
    }
    else if(this.state.isMobile && !this.state.isAndroid){
      currentSystem = "iOs"
    }

    let compatibleSystems = systems.filter(function(x){
      return productOs.includes(x)
    })

    this.setState({
      compatibleSystems:compatibleSystems,
      currentSystem:currentSystem
    })

    //incompatible system block
    if(!productOs.includes(currentSystem)){
      return false
    }

    //catalina version block
    if(product === "Classic" && this.state.isCatalina && compatibleSystems.includes("Osx")){
      return false
    }

    return true

  }

  render() {
    const {text} = this.props.pageContext
    const t = useTranslations(text)

    let currentSystem = getOsName(this.state.currentSystem);
    let productOsName = getOsName(this.state.productOs);

    let product;
    if(this.state.product === "uTorrent Classic"){
      product = "µTorrent Classic"

    }
    else{
      product = `µTorrent ${this.state.product}`
    }


    //buy now, buy with crypto cases
    if(this.state.action === "buying"){
      var text1 = <>
        <p>{t(`This product is for Windows.`)}</p>
        <p>{t(`Are you sure?`)}</p>
      </>

      var text2 = <p><span className="pointer" onClick={()=>{this.openLink(false)}} >{t(`Yes`)}</span> | <span className="pointer" onClick={this.close}>{t(`No`)}</span></p>
    }
    else if(this.state.action === "downloading" && !this.state.isMobile){
      text1 = <>
        <p>{t(`Your device isn’t compatible with ${product} for ${productOsName}.`)}</p>
        <p>{t(`Would you like to download ${product} for ${currentSystem}?`)}</p>
      </>

      text2 =<>
        <p className="mb-5"><span className="pointer" onClick={()=>{this.openLink(true, this.state.currentSystem)}} >[{t(`Yes`)}]</span></p>
        <p className="mb-0"><span className="pointer text-size-14" onClick={()=>{this.openLink(true, this.state.productOs)}}>[{t(`No, please let me continue from this page.`)}]</span></p>
      </> 
    }
    //Android device case
    else if(currentSystem === "Android"){
      text1 = <>
        <p>{t(`Your device isn’t compatible with ${product} for ${productOsName}.`)}</p>
        <p>{t(`Would you like to download µTorrent Android?`)}</p>
      </>

      text2 =<>
        <p className="mb-3"><span className="pointer" onClick={()=>{this.openLink(false, "Android")}} >[{t(`Yes`)}]</span></p>
      </> 
    }
    //iOs device case
    else if(currentSystem === "iOs"){
      text1 = <>
        <p>{t(`Your device isn’t compatible with ${product} for ${productOsName}.`)}</p>
        <p>{t(`Would you like to check out DLive, available on the App Store?`)}</p>
      </>

      text2 =<>
        <p className="mb-3"><span className="pointer" onClick={()=>{this.openLink(false, "iOs")}} >[{t(`Yes`)}]</span></p>
      </> 
    }
    //blocked Language case
    else if(this.state.action === "blockedLanguage"){
      text1 = t("Your preferred language has been updated.")
      text2 = <div className="mb-3">
              <p className="compatibility-text mb-0" >
                {t("However, this page is only available in English.")}
              </p> 
              </div>
    }



    //Catalina case
    if(this.state.isCatalina && this.state.product === "Classic" && this.state.compatibleSystems.includes("Osx")){
      text1 = <>
        <p>
          {t("µTorrent Classic for Mac is not compatible with Mac OS Catalina and above.")}
        </p>
        <p className="compatibility-text" >
          {t("Would you like to download µTorrent Web for Mac?")}
        </p> 
      </>
      text2 = <>
        <p className="mb-3"><span className="pointer" onClick={()=>{this.openLink(true, "catalinaWeb")}} >[{t(`Yes`)}]</span></p>
        <p className="mb-0"><span className="pointer text-size-14" onClick={()=>{this.openLink(true)}}>[{t(`No, please let me continue from this page.`)}]</span></p>

      </> 
    }

    //utorrent classic case
    if(this.state.product === "uTorrent Classic" && !this.state.isMobile && this.state.isMac){
      text1 = <>
        <p>{t(`Your device isn’t compatible with ${product} for ${productOsName}.`)}</p>
        <p>{t(`Would you like to download µTorrent Web for Mac?`)}</p>
      </>
      text2 = <>
        <p className="mb-3"><span className="pointer" onClick={()=>{this.openLink(true, "catalinaWeb")}} >[{t(`Yes`)}]</span></p>
        <p className="mb-0"><span className="pointer text-size-14" onClick={()=>{this.openLink(false, "Win")}}>[{t(`No, please let me continue from this page.`)}]</span></p>
      </> 
    }

    
    return (
      <CompatibilityContext.Provider value={this.compatibilityHandler}>
        {this.props.children}

        <CSSTransition in={this.state.isOpen} timeout={400} classNames="compatability-animation">
          <div className="compatability-popup-container">
            <div className="compatability-popup p-2 m-3">
              <div className="compatability-popup-inner p-2 text-center">
                <div className="position-relative compatibility-popup-close-container">
                  <div className="compatibility-popup-close pointer" onClick={this.close}>
                    <IoMdClose/>
                  </div>
                </div>
                <div className="px-md-5 pt-3 mx-md-5">
                  <div>
                    <IconContext.Provider value={{ className:'compatibility-icons compatibility-icons-large' }}>
                      <IoMdWarning/>
                    </IconContext.Provider>
                  </div>
                  <div className="mt-4 mb-2">
                    <div className="compatibility-text">
                      {text1}
                    </div>
                  </div>
                  <div className="mb-0">
                    {
                      text2
                    }
                    
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </CSSTransition>

      </CompatibilityContext.Provider>
      )

  }
}

function getOsName(currentSystem){
  let value = currentSystem;
  if(Array.isArray(currentSystem)){
    value = currentSystem[0]
  }
  switch(value){
    case "Win":
      return "Windows"
    case "Osx":
      return "Mac"
    default:
      return value

  }
}

export default CompatabilityPopup

