import axios from "axios";

class BenchClient {
  static properties = {};
  static iValue = 3100;
  static endpoint = "https://utbench.utorrent.com";

  static ping(properties = {}) {
    const data = {
      eventName: 'utwebsite',
      action: properties,
      ...BenchClient.properties
    };
    return axios.get(`${BenchClient.endpoint}/e?i=${BenchClient.iValue}&e=${btoa(JSON.stringify(data))}`);
  }

  static stick(properties = {}) {
    BenchClient.properties = {
      ...BenchClient.properties,
      ...properties,
    };
  }
}

export default BenchClient;
