import React from "react"
import logoClassic from "../images/logos/logo-mark.svg"
import logoWeb from "../images/logos/logo-mark-web.svg"

const DEBUG = false

const BREAKPOINTS = [576, 768, 992, 1200, 1500, 1800]

const resizeValues = {
	"Android":{
		fontSizeMax:27,
		fontSizeCoefficient:18
	},
	"for Android":{
		fontSizeMax:27,
		fontSizeCoefficient:19.5
	},
	"Pro for Android":{
		fontSizeMax:20,
		fontSizeCoefficient:24.5
	},
	"Android Pro":{
		fontSizeMax:27,
		fontSizeCoefficient:15
	},
	"Blog":{
		fontSizeMax:27,
		fontSizeCoefficient:15
	},
	"Classic":{
		fontSizeMax:27,
		fontSizeCoefficient:17.5
	},
	"Classic Premium":{
		fontSizeMax:22,
		fontSizeCoefficient:19.5
	},
	"Classic Pro":{
		fontSizeMax:22,
		fontSizeCoefficient:20
	},
	"Classic Ad-Free":{
		fontSizeMax:21.25,
		fontSizeCoefficient:24
	},
	"Classic Pro+VPN":{
		fontSizeMax:20,
		fontSizeCoefficient:25
	},
	"Live":{
		fontSizeMax:27,
		fontSizeCoefficient:17
	},
	"Press Room":{
		fontSizeMax:20,
		fontSizeCoefficient:25
	},
	"Release Notes":{
		fontSizeMax:20,
		fontSizeCoefficient:25
	},
	"Remote":{
		fontSizeMax:28,
		fontSizeCoefficient:16.5
	},
	"Speed":{
		fontSizeMax:30,
		fontSizeCoefficient:16.5
	},
	"Token":{
		fontSizeMax:30,
		fontSizeCoefficient:15.5
	},
	"Web":{
		fontSizeMax:28,
		fontSizeCoefficient:15
	},
	"Web for Mac":{
		fontSizeMax:24,
		fontSizeCoefficient:15.5
	},
	"Web Pro":{
		fontSizeMax:21.5,
		fontSizeCoefficient:17.5
	},
	"Web Pro+VPN":{
		fontSizeMax:21,
		fontSizeCoefficient:22.5
	},
	"Web Premium":{
		fontSizeMax:21,
		fontSizeCoefficient:22
	}
}

/*

Logo will scale text and icon 
based on the parent container's width

in global.css, 
em units are used to establish relative sizing 
between the icon and text

	.logo-icon{
	  width:2.3em;
	  height:auto;
	}
	.logo-title-1{
	  font-size:1.6em;
	}
	.logo-title-2{
	  font-size:1.7em;
	}

em unit scales from the font-size of the parent element

this font size is calculated and 
applied to the parent elements using `style`

	fontSize = parentWidth/fontSizeCoefficient

fontSizeCoefficient determines the scaling size of the logo
fontSizeMax caps the logo size, stopping it from growing

ADDING A NEW LOGO

	find the fontSizeCoefficient 
		- change display to the smallest smallest screen width ie 320px
		- manually adjust value to keep the logo as big as possible without
		  it wrapping

	find the fontSizeMax
		- switch DEBUG to true
		- expand display width until logo is appropriately large
		- find the respective font-size value (fs) in the console

	add these values into resizeValues to be used as default

	*pass the values as props for special cases

TROUBLESHOOTING

	-logo is only shrinking
	 (logo enters a shrinking loop when container width is not set and is determined by the content's width)
		-ensure the parent element has a set width
		or
		-apply fixedParentDistance prop, an int representing the distance to the closest parentNode with a set width
*/



function getStyle(productName, color, secondaryColor, fontSize){
	var style = {
		"color": (color) ? color: '#76B83F',
		"secondaryColor": (secondaryColor) ? secondaryColor: "#76B83F",
		"logo": (productName.includes('Web') || productName === "Remote") ? logoWeb : logoClassic
	}

	if(fontSize !== ''){
		style.fontSize = fontSize
	}

	return style
}

function getbreakpointIndex(sizeArray, size) {
	let length = sizeArray.length
	let returnIndex = length
	for (let i = 0; i < length; i++) {
		if(size > sizeArray[i]) {
			continue
		}
		else {
			returnIndex = i
			break
		}

	}

	return returnIndex
}

class Logo extends React.Component{

	constructor(props){
		super(props);
		this.container = React.createRef()
		this.state = {
			width:'',
			breakpointIndex:BREAKPOINTS.length
		}
	}
	componentDidMount () {
		let breakpointIndex = getbreakpointIndex(BREAKPOINTS, window.innerWidth)
		let width = this.getContainerWidth()
	    this.setState({
	    	width:width,
	    	breakpointIndex:breakpointIndex
	    })

	    window.addEventListener("resize", () => {
	    	//we only want to resize when breakpoints are crossed
	    	//this is tracked with the breakpointIndex
	    	let breakpointIndex = getbreakpointIndex(BREAKPOINTS, window.innerWidth)
	    	if(this.container.current !== null && breakpointIndex !== this.state.breakpointIndex){
	    		let width = this.getContainerWidth()
		    	this.setState({
		    		width: width,
		    		breakpointIndex: breakpointIndex
		    	})
	    	}
	    });
	}

	getContainerWidth() {
		//get width of current container 
		//or of targeted parent container when fixedParentDistance prop is defined
		let container = this.container.current

		if(this.props.fixedParentDistance) {
			for( let i = 0; i < this.props.fixedParentDistance; i ++) {
				container = container.parentNode
			}
		}

		if(DEBUG){
			console.log(`[target container]`)
			console.log(container)
		}

		return container.offsetWidth
	}

	getFontSize(productName){

		let fontSize = ''
		let width = this.state.width

		if(width !== ''){

			let fsCoefficient = (this.props.fsCoefficient === undefined) ? resizeValues[productName]["fontSizeCoefficient"] : this.props.fsCoefficient
			let fsMax = (this.props.fsMax === undefined) ? resizeValues[productName]["fontSizeMax"] : this.props.fsMax

			fontSize = width/fsCoefficient;
			if(fontSize > fsMax){
				fontSize = fsMax
			}
		}

    	return fontSize

	}

	render(){
		const productName = this.props.productName
		const Tag = this.props.tag
		const className = (this.props.className) ? this.props.className : ""
		const fontSize = this.getFontSize(productName)
		const style = getStyle(productName, this.props.color, this.props.secondaryColor, fontSize)

		if(DEBUG){console.log(`[${productName}]`,`parentWidth:${this.state.width}`, `fs:${fontSize}`)}
		
		return(
			<div 
				style={style} 
				className={`d-block logo w-100 ${className}`} 
				ref={this.container}>

				<img 
					src={style.logo} 
					alt="" 
					className={`d-inline img-fluid logo-icon `}/>

				<Tag style={{color: style.color, fontSize: style.fontSize}} className='d-inline logo-text'>

					<span className="font-weight-bold logo-title-1 align-middle mr-1"><span className="logo-t-spacing">µ</span>Torrent</span> <span className={`logo-title-2 align-middle`} style={{color: style.secondaryColor}}>{this.props.productName}</span>
				
				</Tag>
			</div>
		)
	}
}

export default Logo