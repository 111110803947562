const ANDROID_LINK_FREE = "https://play.google.com/store/apps/details?id=com.utorrent.client";
const ANDROID_LINK_PRO = "https://play.google.com/store/apps/details?id=com.utorrent.client.pro";
function getAndroidLink(type = "free") {
  if(type !== 'free' && type !== 'pro') throw "android link type must be 'free' or 'pro'";
  
  let link = (type === "pro") ? ANDROID_LINK_PRO : ANDROID_LINK_FREE;
  if((typeof window !== "undefined") && window.gatsbyIsMobile) {
    link = link + '&utm_campaign=UT_prod';
  }
  return link;
}

export { ANDROID_LINK_FREE, ANDROID_LINK_PRO, getAndroidLink };