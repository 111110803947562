import React from "react"
import loadable from '@loadable/component'
import { Link } from "gatsby"
import LocalizedLink from "./localizedLink"
import useTranslations from "../utils/useTranslations"
// import NavPopup from "./navPopup"
import  NavItem2  from "./NavItem2";
import logo from "../images/logo-green.svg"
import { FaWindows, FaApple, FaLinux, FaAndroid } from 'react-icons/fa';
import { CompatibilityContext } from "../components/compatibilityContext";
import { removeTrailingSlash } from "../utils/gatsby-node-helpers";
import { unlocalizedPageCheck } from "../utils/unlocalizedPageCheck"

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { NavContext } from "./NavContext";

const NavPopup = loadable(() => import('./navPopup'));


class NavbarMain extends React.Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.state = {
      isOpen: false,
      isPopupOpen: false,
      selected: null,
      navPopupOffset: undefined
    };
    this.myRef = React.createRef();

  }

  componentDidMount() {
    setTimeout(() => {
      this.getOffset()
    }, 1000)

  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.navText !== prevProps.navText) {
      setTimeout(() => {
        this.getOffset()
      }, 1000)
    }
  }

  getOffset() {
    let left = this.myRef.current.offsetLeft
    let w = window.innerWidth
    let offsetRight = w - left
    this.setState({navPopupOffset: offsetRight})
  }

  toggle() {
    if(!this.state.isOpen) {
      window.document.body.setAttribute("style", "overflow:scroll")
    }
    this.setState({
      isOpen: !(this.state.isOpen || this.state.isPopupOpen),
      isPopupOpen: (!this.state.isOpen) ? false : !this.state.isOpen
    });
  }

  togglePopup(selected) {
    if(selected !== null){
      window.document.body.setAttribute("style", "overflow:hidden")
      this.setState({
        isPopupOpen: true,
        selected:selected
      });
    }
    else{
      window.document.body.setAttribute("style", "overflow:scroll")
      this.setState({
        isPopupOpen: false,
        selected:selected
      });
    }

  }

  render() {
    const t = useTranslations(this.props.navText)
    return (
      <>
        { (this.state.isOpen || this.state.isPopupOpen) &&
          <div className="nav-bg-container" onClick={this.toggle}></div>
        }
        <Navbar light expand="md" style={{ backgroundColor: `#ffffff`, boxShadow:'0px 53px 300px -25px rgba(0,0,0,0.75)'}} >
        <div className="container px-0">
          <span onClick={()=>{this.togglePopup(null)}}>
            <LocalizedLink to="/" id="mainnav-bt-logo">
              <div className="navbar-brand mb-0"><img src={logo} style={{ height: `40px` }} alt="µTorrent - The Better Torrent Client"/></div>
            </LocalizedLink>
          </span>
          <NavbarToggler onClick={this.toggle} />
          <Collapse  isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto d-flex d-md-none " navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {t(`Products`)}
                </DropdownToggle>
                <DropdownMenu right>
                  <h6 className="dropdown-header">µTorrent Web</h6>
                  <div className="position-relative" >
                    <FaWindows className="position-absolute nav-mobile-icon"/>
                    <LocalizedLink to="/web/" id="mainnav-products-btweb-windows-mobile" className="nav-mobile-link dropdown-item" onClick={this.toggle}>
                        Windows
                    </LocalizedLink>
                  </div>
                  <div className="position-relative" >
                    <FaApple className="position-absolute nav-mobile-icon"/>
                    <LocalizedLink to="/web/mac/" id="mainnav-products-btweb-mac-mobile" className="nav-mobile-link dropdown-item" onClick={this.toggle}>
                      Mac
                    </LocalizedLink>
                  </div>
                  <DropdownItem divider />

                  <h6 className="dropdown-header">µTorrent Classic</h6>
                  <div className="position-relative" >
                    <FaWindows className="position-absolute nav-mobile-icon"/>
                    <LocalizedLink to="/desktop/" id="mainnav-products-btclassic-windows-mobile" className="nav-mobile-link dropdown-item" onClick={this.toggle}>
                        Windows
                    </LocalizedLink>
                  </div>
                  <div className="position-relative" >
                    <FaApple className="position-absolute nav-mobile-icon"/>
                    <LocalizedLink to="/downloads/mac/" id="mainnav-products-btclassic-mac-mobile" className="nav-mobile-link dropdown-item" onClick={this.toggle}>
                      Mac
                    </LocalizedLink>
                  </div>
                  <div className="position-relative" >
                    <FaLinux className="position-absolute nav-mobile-icon"/>
                    <LocalizedLink to="/downloads/linux/" id="mainnav-products-btclassic-linux-mobile" className="nav-mobile-link dropdown-item" onClick={this.toggle}>
                      Linux
                    </LocalizedLink>
                  </div>
                  <DropdownItem divider />

                  <LocalizedLink to="/mobile/" id="mainnav-products-android-mobile" className="" onClick={this.toggle}>
                    <h6 className="dropdown-header">
                      µTorrent Android
                    </h6>
                  </LocalizedLink>

                  <DropdownItem divider />
                  <LocalizedLink to="/remote/" id="mainnav-products-btremote-mobile" className="" onClick={this.toggle}>
                    <h6 className="dropdown-header">
                      µTorrent Remote
                    </h6>
                  </LocalizedLink>
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem>
                <a href="https://forum.utorrent.com/" id="mainnav-forum-mobile" className="nav-link text-decoration-none" target="_blank" rel="noopener noreferrer" onClick={this.toggle}>
                  {t(`Forum`)}
                </a>
              </NavItem>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {t(`Language`)}
                </DropdownToggle>
                <DropdownMenu right>
                  <div className="nav-mobile-languages">
                    
                    <DropdownItem onClick={this.toggle} >
                      <Link to={ this.props.unlocalizedPath } hrefLang="en" id="mainnav-lang-en-mobile" className="dropdown-item">
                        English
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/intl/zh_cn'+ this.props.unlocalizedPath } hrefLang="zh-cn" id="mainnav-lang-zh-cn-mobile" className="dropdown-item">
                        中文（简体）
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/intl/zh_tw'+ this.props.unlocalizedPath } hrefLang="zh-tw" id="mainnav-lang-zh-tw-mobile" className="dropdown-item">
                        中文（繁體）
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/intl/de'+ this.props.unlocalizedPath } hrefLang="de" id="mainnav-lang-de-mobile" className="dropdown-item">
                        Deutsch
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/intl/es'+ this.props.unlocalizedPath } hrefLang="es" id="mainnav-lang-es-mobile" className="dropdown-item">
                        Español
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/intl/fr'+ this.props.unlocalizedPath } hrefLang="fr" id="mainnav-lang-fr-mobile" className="dropdown-item">
                        Français
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/intl/hi'+ this.props.unlocalizedPath } hrefLang="hi" id="mainnav-lang-hi-mobile" className="dropdown-item">
                       हिन्दी
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/intl/it'+ this.props.unlocalizedPath } hrefLang="it" id="mainnav-lang-it-mobile" className="dropdown-item">
                        Italiano
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/intl/ja'+ this.props.unlocalizedPath } hrefLang="ja" id="mainnav-lang-ja-mobile" className="dropdown-item">
                        日本語
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/intl/ko'+ this.props.unlocalizedPath } hrefLang="ko" id="mainnav-lang-ko-mobile" className="dropdown-item">
                        한국어
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/intl/nl'+ this.props.unlocalizedPath } hrefLang="nl" id="mainnav-lang-nl-mobile" className="dropdown-item">
                        Nederlands
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/intl/pl'+ this.props.unlocalizedPath } hrefLang="pl" id="mainnav-lang-pl-mobile" className="dropdown-item">
                        Polski
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/intl/pt_br'+ this.props.unlocalizedPath } hrefLang="pt-br" id="mainnav-lang-pt-br-mobile" className="dropdown-item">
                        Português (Brasil)
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/intl/pt'+ this.props.unlocalizedPath } hrefLang="pt" id="mainnav-lang-pt-mobile" className="dropdown-item">
                        Português
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/intl/ru'+ this.props.unlocalizedPath } hrefLang="ru" id="mainnav-lang-ru-mobile" className="dropdown-item">
                        Русский
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/intl/tl'+ this.props.unlocalizedPath } hrefLang="tl" id="mainnav-lang-tl-mobile" className="dropdown-item">
                        Tagalog
                      </Link>
                    </DropdownItem>
                    <DropdownItem onClick={this.toggle}>
                      <Link to={ unlocalizedPageCheck(this.props.unlocalizedPath) ? this.props.unlocalizedPath : '/intl/tr'+ this.props.unlocalizedPath } hrefLang="tr" id="mainnav-lang-tr-mobile" className="dropdown-item">
                        Türkçe
                      </Link>
                    </DropdownItem>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>

          <NavContext.Provider value={{isPopupOpen : this.state.isPopupOpen, selected: this.state.selected}}>
         
          <div className="d-none d-md-inline-block" ref={this.myRef}>
            <NavItem2 caret togglePopup={this.togglePopup} intName={t("Products")} name="Products" id="mainnav-products" />
            <NavItem2 name={t('Forum')} href="https://forum.utorrent.com/" id="mainnav-forum" />
            <NavItem2 last caret togglePopup={this.togglePopup} intName={t(`Language`)} name="Language" id="mainnav-language" />
          </div>
          <CompatibilityContext.Consumer>
              {(compatibilityContext) => (
                  <NavPopup togglePopup={this.togglePopup} unlocalizedPath={this.props.unlocalizedPath} navText={this.props.navText} compatibilityContext={compatibilityContext} offset={this.state.navPopupOffset}/>
              )}
            
          </CompatibilityContext.Consumer>
          </NavContext.Provider>

        </div>
        </Navbar>
      </>
    );
  }
}

const Navigation = ({pageContext: { unlocalizedPath, navText }}) => {
  const t = useTranslations(navText)
  return (    
     <NavbarMain unlocalizedPath={unlocalizedPath} navText={navText} t = {t}/>
  )
}

export { NavContext }

export default Navigation

