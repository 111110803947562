// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-desktop-compare-js": () => import("./../../../src/pages/desktop/compare.js" /* webpackChunkName: "component---src-pages-desktop-compare-js" */),
  "component---src-pages-desktop-index-js": () => import("./../../../src/pages/desktop/index.js" /* webpackChunkName: "component---src-pages-desktop-index-js" */),
  "component---src-pages-downloads-android-js": () => import("./../../../src/pages/downloads/android.js" /* webpackChunkName: "component---src-pages-downloads-android-js" */),
  "component---src-pages-downloads-complete-track-stable-os-mac-index-js": () => import("./../../../src/pages/downloads/complete/track/stable/os/mac/index.js" /* webpackChunkName: "component---src-pages-downloads-complete-track-stable-os-mac-index-js" */),
  "component---src-pages-downloads-complete-track-stable-os-win-index-js": () => import("./../../../src/pages/downloads/complete/track/stable/os/win/index.js" /* webpackChunkName: "component---src-pages-downloads-complete-track-stable-os-win-index-js" */),
  "component---src-pages-downloads-linux-js": () => import("./../../../src/pages/downloads/linux.js" /* webpackChunkName: "component---src-pages-downloads-linux-js" */),
  "component---src-pages-downloads-mac-js": () => import("./../../../src/pages/downloads/mac.js" /* webpackChunkName: "component---src-pages-downloads-mac-js" */),
  "component---src-pages-downloads-win-js": () => import("./../../../src/pages/downloads/win.js" /* webpackChunkName: "component---src-pages-downloads-win-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-licensed-samples-index-js": () => import("./../../../src/pages/licensed-samples/index.js" /* webpackChunkName: "component---src-pages-licensed-samples-index-js" */),
  "component---src-pages-mobile-index-js": () => import("./../../../src/pages/mobile/index.js" /* webpackChunkName: "component---src-pages-mobile-index-js" */),
  "component---src-pages-pro-index-js": () => import("./../../../src/pages/pro/index.js" /* webpackChunkName: "component---src-pages-pro-index-js" */),
  "component---src-pages-prodnews-index-js": () => import("./../../../src/pages/prodnews/index.js" /* webpackChunkName: "component---src-pages-prodnews-index-js" */),
  "component---src-pages-remote-index-js": () => import("./../../../src/pages/remote/index.js" /* webpackChunkName: "component---src-pages-remote-index-js" */),
  "component---src-pages-sr-js": () => import("./../../../src/pages/sr.js" /* webpackChunkName: "component---src-pages-sr-js" */),
  "component---src-pages-web-compare-js": () => import("./../../../src/pages/web/compare.js" /* webpackChunkName: "component---src-pages-web-compare-js" */),
  "component---src-pages-web-downloads-complete-track-stable-os-mac-index-js": () => import("./../../../src/pages/web/downloads/complete/track/stable/os/mac/index.js" /* webpackChunkName: "component---src-pages-web-downloads-complete-track-stable-os-mac-index-js" */),
  "component---src-pages-web-downloads-complete-track-stable-os-win-index-js": () => import("./../../../src/pages/web/downloads/complete/track/stable/os/win/index.js" /* webpackChunkName: "component---src-pages-web-downloads-complete-track-stable-os-win-index-js" */),
  "component---src-pages-web-index-js": () => import("./../../../src/pages/web/index.js" /* webpackChunkName: "component---src-pages-web-index-js" */),
  "component---src-pages-web-mac-js": () => import("./../../../src/pages/web/mac.js" /* webpackChunkName: "component---src-pages-web-mac-js" */),
  "component---src-pages-web-pro-js": () => import("./../../../src/pages/web/pro.js" /* webpackChunkName: "component---src-pages-web-pro-js" */),
  "component---src-pages-webpro-offer-index-js": () => import("./../../../src/pages/webpro-offer/index.js" /* webpackChunkName: "component---src-pages-webpro-offer-index-js" */)
}

