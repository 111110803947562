import React from "react"
import styles from "./navPopup.module.css"
import { NavContext } from "./NavContext"
import { FaCaretRight } from 'react-icons/fa';
import { IoMdGlobe } from 'react-icons/io';




class NavItem2 extends React.Component {

	constructor(props){
		super(props);

		this.toggle = this.toggle.bind(this);

	}

	toggle() {
		if(this.context.selected === this.props.name){
			this.props.togglePopup(null)

		}	
		else{


			this.props.togglePopup(this.props.name)
		}

	}

	render(){
		if(!this.props.caret){
			return(
		    <a id={this.props.id} className={`${styles.navItem} ml-5`} style={{ marginRight: 0 }} href={this.props.href} target="_blank" rel="noopener noreferrer">
		    	{this.props.name} 
				</a>
				)
		}

		

		let isSelected = this.context.isPopupOpen && (this.context.selected === this.props.name)

		let rotate = isSelected ? styles.rotate : ""

		let selectedFill = isSelected ? styles.selectedFill : ""

			if(this.props.last){
				return(

				    <a id={this.props.id} onClick={this.toggle} className={`${styles.navItem} ${selectedFill} ml-5`} style={{ marginRight: 0 }}>
				    	{this.props.intName} 
						</a>
					)
			} else {
				return(

				    <a id={this.props.id} onClick={this.toggle} className={`${styles.navItem} ${selectedFill}`} style={{ marginRight: 0 }}>
				    	{this.props.intName} 
						</a>
					)
			}
		}
}

NavItem2.contextType = NavContext;

export default NavItem2